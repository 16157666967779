@import "color.scss";
$custom-radio-size: 25px;
$custom-radio-border-width: 2px;
$custom-radio-transition-speed: 100ms;
$custom-radio-inset-shadow: inset 0 0.1em 1px -0.1em rgba(0, 0, 0, 0.3);

$custom-radio-pip-color: green;
$custom-radio-pip-size: round($custom-radio-size * 0.6);

// States
$custom-radio-unchecked-bg: transparent;
$custom-radio-unchecked-border: $bg-color;

$custom-radio-checked-bg: transparent;
$custom-radio-checked-border: $bookmark-color;

$custom-radio-active-inset-shadow: $bookmark-color;

$custom-radio-focus-shadow: $bookmark-color;

$custom-radio-disabled-bg: yellow;
$custom-radio-disabled-pip-color: yellow;

// Check variable values
@if ($custom-radio-size % 2 == 1) {
  @error '$custom-radio-size must be even';
}
// round to nearest even number
@if ($custom-radio-pip-size % 2 == 1) {
  $custom-radio-pip-size: $custom-radio-pip-size - 1;
}

// custom checkbox

.ct-checkbox {
  /* ...existing styles */
  display: grid;
  place-content: center;
  width: 20px;
  height: 20px;
}

.ct-checkbox::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em $btn-active-color;
  background-color: $btn-active-color;
  border-color: $btn-active-color;
  border-width: 1px;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

.ct-checkbox:checked::before {
  transform: scale(1);
}

// custom radio

input[type="radio"] {
  position: relative;
  display: inline-block;

  width: $custom-radio-size - 2;
  height: $custom-radio-size - 2;

  border-radius: 100%;
  outline: none !important;
  -webkit-appearance: none;

  // Radio
  // -----

  &::before {
    position: relative;
    top: -$custom-radio-border-width;
    left: -$custom-radio-border-width;
    display: block;
    content: "";

    background: $custom-radio-unchecked-bg;
    border: $custom-radio-border-width solid $custom-radio-unchecked-border;
    border-radius: 100%;
    box-shadow: $custom-radio-inset-shadow;

    width: $custom-radio-size;
    height: $custom-radio-size;
  }

  &:active::before {
    box-shadow: $custom-radio-inset-shadow, $custom-radio-active-inset-shadow;
  }

  &:focus::before {
    box-shadow: $custom-radio-inset-shadow, $custom-radio-focus-shadow;
  }

  &:checked::before {
    background: $custom-radio-checked-bg;
    border-color: $bookmark-color;
  }

  &:disabled::before {
    cursor: not-allowed;
    background-color: $custom-radio-disabled-bg;
    border-color: transparentize($custom-radio-unchecked-border, 0.2);
  }

  // Radio Pip
  // ---

  &::after {
    position: relative;
    top: 10;
    left: 0;

    display: block;
    content: "";

    background: $bookmark-color;
    border-radius: 100%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);

    width: 0;
    height: 0;
  }

  &:checked::after {
    transition: all ease-in-out $custom-radio-transition-speed 0;

    top: -22px;

    left: 3px;

    width: $custom-radio-pip-size;
    height: $custom-radio-pip-size;
  }

  &:disabled::after {
    background: red;
  }
}
.selected_color {
  color: $bookmark-color;
}
