@import "color.scss";
@import "breakpoint.scss";
@import "mixin.scss";
@import "common.scss";
// @import "~bootstrap/scss/bootstrap";

$spacing_limit: 49;
$spacing_includes: 50, 60, 70, 75, 77, 80, 85, 90, 100, 150, 200; // must greater than $spacing_limit

html {
  overflow: hidden;
}

body {
  background-color: $bg-color;
  font-family: "Noto Sans JP", "Noto Sans", sans-serif; // phase-2
  // font-family: "Meiryo UI"; // phase-1
  font-size: 18px;
  color: $color;
  overflow: hidden;
  margin: 0px;
  padding: 0px;

  .subbody {
    height: $heightWithoutNav;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  max-width: $maxWidth;
}

// .container,
// .container-fluid {
//     max-width: 1728px;

//     &.container-full {
//         max-width: unset;
//     }
// }

// .navbar {

//     .container,
//     .container-fluid {
//         max-width: unset;
//     }
// }

#w3a-modal {
  z-index: 9999 !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $placeholder-color;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $placeholder-color;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $placeholder-color;
}

input,
textarea {
  &:focus::placeholder {
    color: transparent;
  }
}

.overlay {
  &::after {
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.8);
    position: fixed;
    z-index: 999;
  }
}

.italic {
  font-style: italic;
}

.bg-2 {
  background-color: $bg-color-2 !important;
}

.bg-none {
  background: none !important;
}

.nav_small {
  font-size: 16px !important;
}

.nav_margin {
  margin-bottom: 15px !important;
}

.copy_small {
  font-size: 14px !important;
  margin-top: 11px;
}

option {
  font-size: 20px;
}

.custom_modal_title {
  font-size: 20px !important;
  margin-bottom: 45px;
}

.custom_modal_text {
  font-size: 16px !important;
  margin-bottom: 53px;
}

.btn_margin {
  margin-bottom: 78px;
}

.logo {
  max-width: 181px;

  @include media-breakpoint-down("lg") {
    max-width: 100px;
  }

  @include media-breakpoint-down("sm") {
    max-width: 80px;
  }
}

.icon {
  fill: $color;

  &.icon-gradient {

    &.active,
    &:hover {
      // stroke: linear-gradient(90deg, #E85EFF 0%, #2DD9FF 100%);
      // filter: url(#filter_paint);
      fill: url(#rainbow_paint) !important;
      -webkit-transition: fill 0.3s ease-in-out;
      -moz-transition: fill 0.3s ease-in-out;
      -o-transition: fill 0.3s ease-in-out;
      transition: fill 0.3s ease-in-out;
    }
  }

  &.disabled {
    fill: $default-color;
  }
}

.placeholder-align-center {
  input::placeholder {
    text-align: center;
  }
}

.icon-stroke {
  stroke: $color;

  &.active {
    // stroke: linear-gradient(90deg, #E85EFF 0%, #2DD9FF 100%);
    // filter: url(#filter_paint);
    stroke: url(#rainbow_paint);
  }
}

.camera-circle {
  width: 40px;
  height: 40px;
  border-radius: 150px;
  background: $modal-bg-color;
  @include flex-center;
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.title {
  font-weight: normal !important;
}

.text-justify {
  text-align: justify !important;
}

.text-danger {
  color: $text-danger-color !important;
}

.bg-highlight {
  background-color: $bookmark-color !important;
}

.bg-highlight-2 {
  background-color: #03ffff !important;
}

.badge-wrapper {
  i.icon {
    position: absolute;
  }

  .badge-custom {
    @include flex-center;
    background-color: $badge-color !important;
    border-radius: 50px;
    width: 15px;
    height: 15px;
    font-size: 8px;
    position: relative;
    top: -9px;
    left: 9px;
  }
}

.flex-center {
  @include flex-center;
}

.d-none-important {
  display: none !important;
}

.thumbnail {
  @include thumbnail;
}

.pointer {
  cursor: pointer;
}

.divider {
  border-bottom: 1px solid;
  border-color: $border-color;
}

.divider-dashed {
  border-bottom: 1px dashed;
  border-color: $border-color;
}

.divider-primary {
  border-bottom: 1px solid;
  border-color: #495376;
}

.alert-default {
  border: 1px solid $color;
}

.form-check-label {
  cursor: pointer;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-bold {
  font-weight: bold !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.ml--10 {
  margin-left: -10px;
}

a {
  cursor: pointer;
}

a.disabledBtn {
  pointer-events: none;
  cursor: default;
}

.min-w-auto {
  min-width: auto !important;
}

.max-w-auto {
  max-width: auto !important;
}

.col-res {
  @media screen and (min-width: 992px) and (max-width: 1150px) {
    &.item-col {
      width: 50% !important;
    }
  }

  @media screen and (min-width: 1400px) and (max-width: 1450px) {
    &.item-col {
      width: 33.33333% !important;
    }
  }

  @each $size, $value in $resbreakpoints {
    &-#{$size} {
      @each $width, $percent in $customCol {
        &-#{$width} {
          @include col-res($size, $percent);
        }
      }
    }
  }
}

.text-disable {
  color: $disable-color;
}

.search-input-group,
.search-input-group-2,
.search-input-group-3 {
  width: 500px !important;
  max-width: 100%;
  box-shadow: 1px 1px 10px #00000010;

  @include media-breakpoint-down("sm") {
    width: 100% !important;
    margin-top: 15px;
  }

  &.border-input {
    box-shadow: none;

    input {
      border: 1px solid rgba($color, 0.5) !important;
      border-radius: 10px !important;
    }
  }

  .search-icon,
  .search-icon-2,
  .search-icon-3 {
    position: absolute;
    top: 12px;
    left: 15px;
    z-index: 5;
  }

  .search-icon-3 {
    top: 16px;
  }

  .search-icon {
    top: 25px;

    @include media-breakpoint-down("xxl") {
      top: 15px;
    }
  }

  .search-icon-2 {
    top: 10px;
  }

  .search-input,
  .search-input-2,
  .search-input-3 {
    border: none;
    background-color: $bg-color-2;
    padding-left: 48px;
    // padding-top: 4px;
    padding-bottom: 8px;
    // width: 1% !important;

    @include media-breakpoint-down("sm") {
      width: 1% !important;
    }
  }

  .search-input {
    height: 70px;

    @include media-breakpoint-down("xxl") {
      height: 50px;
    }
  }

  .search-input-2 {
    background-color: $bg-color;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }

  .search-input-3 {
    background-color: $bg-color-2;
    height: 58px;
    border-radius: 0;
    padding-right: 28px;
  }

  .input-group-text {
    border: none;
    height: 100%;
    background-color: $bg-color-2;
  }
}

.search-input-group {
  width: 700px !important;

  @include media-breakpoint-down("xxl") {
    width: 500px !important;
  }
}

.search-input-group-2 {
  // @include media-breakpoint-down('xxl') {
  //     width: 350px !important;
  // }

  @media (min-width: 1200px) and (max-width: 1300px) {
    width: 280px !important;
  }

  @media (min-width: 1300px) and (max-width: 1400px) {
    width: 350px !important;
  }

  @media (min-width: 1400px) and (max-width: 1600px) {
    width: 420px !important;
  }

  @include media-breakpoint-down("xl") {
    width: 220px !important;
  }
}

.search-input-group-3 {
  width: 100% !important;
  margin-top: 0px !important;

  .btn-custom-close {
    width: 15px !important;
    height: 15px !important;
    top: 21.5px;
    right: 50px;
    stroke: $color !important;
    // opacity: 0.5 !important;
    z-index: 5;
  }
}

select.sort {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: $color;
  background-image: url(../icons/sort_white.svg);

  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: center;
  color: #fff;

  &.sort-sm {
    @extend .btn;
    @extend .btn-custom-5;
    @extend .btn-100;
    @extend .btn-withicon;
    background-image: url(../icons/sort_primary.svg);
    background-position-x: 75%;
    border-width: 1px !important;
  }
}

.arriaval-horiz-scroll {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.navbar {
  box-shadow: 0px 4px 4px rgba($color, 0.25);
  background-color: $bg-color-2;
  margin-bottom: 0px;
  -webkit-transform: translate3d(0, 0, 0);
}

small {
  font-size: 12px;

  &.lg {
    font-size: 14px;
  }
}

p {
  font-size: 18px;
}

h1 {
  font-size: 3.75rem;

  @include media-breakpoint-down("xxl") {
    font-size: 3rem;
  }

  @include media-breakpoint-down("xl") {
    font-size: 2.75rem;
  }

  @include media-breakpoint-down("lg") {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: 2.5rem;
  font-weight: 700px;

  @include media-breakpoint-down("xxl") {
    font-size: 1.875rem;

    svg {
      width: 40px;
    }
  }

  @include media-breakpoint-down("xl") {
    font-size: 1.563rem;

    svg {
      width: 35px;
    }
  }

  @include media-breakpoint-down("md") {
    font-size: 1.25rem;

    svg {
      width: 35px;
    }
  }
}

h3,
.header {
  font-size: 1.875rem;
}

h4 {
  font-size: 1.563rem;
}

.btn {
  padding-top: 4px;
  padding-bottom: 8px;
  z-index: 0;

  .ladda-label {
    @include flex-center;
  }
}

.btn-mini {
  min-width: 200px !important;
}

.btn-fit {
  width: fit-content !important;
}

.btn-custom-close {
  position: absolute;
  top: 28px;
  right: 15px;
  text-align: right;
  fill: #fff !important;
  stroke: #fff !important;
  opacity: 1;

  @include media-breakpoint-up("lg") {
    display: none;
  }
}

.sort_modal {
  background: linear-gradient(180deg, $color 0%, #21283c 100%);
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 7546566;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 13px;

  .modal_header {
    display: flex;
    justify-content: space-between;
  }

  .header_left {
    color: $bg-color;
    font-size: 25;
  }

  .btn_close_white {
    stroke: #fff !important;
  }

  .modal_body {
    display: flex;
    justify-content: space-between;
  }

  .label_color {
    color: $bg-color;
  }
}

.swal2-close:focus {
  box-shadow: none;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: none;
}

.btn-bold {
  font-weight: bold;
}

.btn-custom,
.btn-custom:hover {
  @include btn-custom;
  color: $color !important;
}

.btn-custom-2,
.btn-custom-2:hover {
  @include btn-custom($btn-color-2);
  color: #fff !important;
}

.btn-custom-3,
.btn-custom-3:hover {
  @include btn-custom($btn-color-3);
  color: #fff !important;
}

.btn-custom-4,
.btn-custom-4:hover {
  @include btn-custom($btn-color-4);
}

.btn-custom-5,
.btn-custom-5:hover,
.btn-custom-5:focus {
  @include btn-custom(white, $color, 2px);
  color: $color;
}

.btn-custom-6,
.btn-custom-6:hover {
  @include btn-custom($color);
}

.btn-custom-7,
.btn-custom-7:hover {
  @include btn-custom($btn-color-7);
}

.btn-dim {
  @include btn-custom(rgba($color, 0.5));
  background-color: transparent !important;
  border-color: rgba($color, 0.5) !important;
  color: rgba($color, 0.5) !important;
  min-width: 150px;

  &:hover {
    background-color: $color !important;
    // border-color: $color;
    border: none;
    color: #fff !important;
  }
}

.btn-edit,
.btn-edit:hover {
  @include btn-custom($btn-edit);
}

.btn-disable,
.btn-disable:hover {
  @include btn-custom($btn-disable);
  color: $disable-color !important;
}

.btn-reset {
  // background-color: $default-color;
  // color: inherit;
  min-width: inherit;
}

.btn-register {
  width: 250px !important;
  height: 50px;
}

.btn-withicon {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
  color: $color;
}

.btn-100 {
  width: 100% !important;
  max-width: 400px;
  border-radius: 5px;
}

.profile-pic {
  @include profile-pic(40px, 40px);
  object-fit: cover;
  object-position: center;

  &.element-frame {
    .element_fadeInOut {
      position: relative;
      margin: 0 auto;
      width: 60px;
      height: 60px;
      @include flex-center;

      .element_fadeInOut_item {
        position: absolute;
        left: 0;
        -webkit-transition: opacity 1s ease-in-out;
        -moz-transition: opacity 1s ease-in-out;
        -o-transition: opacity 1s ease-in-out;
        transition: opacity 1s ease-in-out;

        svg {
          width: 60px !important;
          height: 60px !important;
        }

        &.top {
          animation-name: cf1FadeInOut;
          animation-timing-function: ease-in-out;
          animation-iteration-count: infinite;
          animation-duration: 5s;
          animation-direction: alternate;
        }
      }
    }
  }
}

.attribute {
  @include media-breakpoint-between("lg", "xl") {
    span {
      font-size: 12px !important;

      &.attribute-value {
        font-size: 14px !important;
      }
    }
  }
}

.attribute-value {
  color: $color;
}

.token-cell {
  img {
    @include thumbnail;
    margin-right: 15px;
  }

  .name {
    // font-weight: bold;
    text-decoration-line: underline;
    word-break: break-word;
  }
}

.money {
  text-align: right;
  justify-content: flex-end;
}

.td-status {
  font-weight: bold;
}

.contract {
  display: flex;
  flex-direction: column;
  align-items: center;

  span.td-status {
    color: $btn-color-4;
    margin-bottom: 5px;
  }

  .btn {
    background-color: $btn-color-4;
  }
}

.span-custom-1 {
  color: $btn-color-4;
}

.view-more {
  @include gradient-text-icon-hover(url(../icons/plus_circle_default.svg),
    url(../icons/plus_circle_gradient.svg));
}

.user-icon {
  @include gradient-icon-hover(url(../icons/user_default.svg),
    url(../icons/user_gradient.svg));
}

.bell-icon {
  @include gradient-icon-hover(url(../icons/bell_default.svg),
    url(../icons/bell_gradient.svg));
}

.wallet-icon {
  @include gradient-icon-hover(url(../icons/wallet.svg),
    url(../icons/wallet_gradient.svg));
}

#mainNav {
  @include media-breakpoint-up("lg") {
    padding: 12px 0 !important;
  }

  .navbar-toggler {
    margin-left: 18px;
    border: none;
    padding: 0;
    margin-top: -3px;
  }

  #navbarSupportedContent.navbar-collapse {
    @include media-breakpoint-down("lg") {
      @include flex-center;

      width: 100vw;
      height: 100vh;
      position: absolute;
      top: -100vh;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: $bg-color;
      transition: all 0.5s ease-in-out;
      // transform-origin: left top;
      overflow-y: scroll;
      // transform: scaleY(0);

      &.show {
        // transform: scaleY(1);
        top: 0;
      }

      .nav-link {
        margin-left: 0;
      }

      .logo {
        max-width: 160px;
        // margin-top: 50px;
        // margin-bottom: 50px;
      }
    }

    .navbar-nav {
      @include media-breakpoint-down("lg") {
        flex-direction: column !important;
        margin-left: unset !important;
        width: 100vw;
        height: 100vh;
        max-height: 500px;
        padding-top: 40px;
        justify-content: space-around;
      }
    }
  }

  .nav-item {
    margin-bottom: 0;

    .nav-link {
      margin-left: 18px;
      margin-right: 13px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $color;

      // &:hover {
      //     @include text-rainbow;
      // }

      span {
        font-size: 1.25rem;

        @include media-breakpoint-down("xl") {
          font-size: 1rem;
        }

        @include media-breakpoint-down("sm") {
          font-size: 25px;
        }
      }

      img {
        width: 30px;
        height: 30px;

        .icon {
          width: auto;
          height: 26px;
        }
      }
    }

    .nav-link:last-child {
      margin-right: 0;
      padding-right: 0;
    }

    .icon {
      width: 26px;
      height: 26px;
    }

    &.close {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }

  .dropdown-toggle::after {
    display: none;
  }

  @include media-breakpoint-down("md") {
    .navbar-nav {
      flex-direction: row !important;
      justify-content: space-evenly;
    }
  }
}

#subNav {
  margin-bottom: 40px;
  z-index: 2;
  min-height: 34px;

  .nav-align {
    display: flex;
    justify-content: start;
    gap: 50px;

    @include media-breakpoint-down("sm") {
      display: flex;
      justify-content: space-around;
    }
  }

  @include media-breakpoint-down("sm") {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }

  .nav-tabs .nav-item .nav-link:not(.active),
  .nav-pills .nav-item .nav-link:not(.active) {
    background-color: transparent;
    color: black;
    font-weight: 300;
    border-color: #131212;
  }

  .nav-tabs .nav-item .nav-link:not(.active),
  .nav-pills .nav-item .nav-link:not(.active):hover {
    background-color: #495376;
    color: white;
  }

  ul.navbar-nav {
    display: flex;
    flex-direction: row;
    // float: left;

    li {
      margin-right: 30px;

      @include media-breakpoint-down("sm") {
        margin-right: 26px;
      }
    }

    .nav-item {
      margin-bottom: 0px;

      .nav-link {
        font-weight: bold;

        // &.active.border-bottom {
        //     border-bottom: 5px solid $tab-color  !important;
        // }
      }
    }

    &.nav-pills {
      .nav-item {
        a {
          width: 140px;
          text-align: center;
          padding: 4px;

          @include media-breakpoint-down("sm") {
            padding: 8px;
          }
        }
      }
    }
  }

  .external-link {
    float: right;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

#subNav.empty {
  margin-bottom: 0;
  padding-bottom: 40px;
}

#subNavBar {
  .active {
    background-color: $default-color;
    color: $disable-color;
    border: 1px solid $default-color;
  }
}

.card {
  border-radius: 0;
  border: none;
}

.horiz-scroll-container {
  margin-left: -60px;
  margin-right: -60px;
  padding: 0 60px 0 60px;
  -webkit-overflow-scrolling: auto;

  @include media-breakpoint-down("xxxl") {
    width: 100vw;
    padding: 0 50px 0 50px;
    margin-left: -50px;
    margin-right: -50px;
  }

  @include media-breakpoint-down("lg") {
    width: 100vw;
    padding: 0 13px 0 13px;
    margin-left: -13px;
    margin-right: -13px;
  }

  // position: relative;
  // left: calc(-50vw + 50%);
}

.item {
  // min-width: 328px;
  // max-width: 100%;
  min-width: 263px;
  max-width: 328px;

  @include media-breakpoint-down("xxl") {
    min-width: 263px;
  }

  @include media-breakpoint-down("sm") {
    min-width: 180px !important;
    max-width: 180px !important;
  }

  h5.card-title {
    height: 24px;
  }
}

.item-list {
  .item {
    min-width: 245px;
    @include media-breakpoint-down("xxxl") {
      min-width: 245px;
    }
  }

  .fixed {
    position: fixed;
    z-index: 100;
  }
}

.item-card {
  .card-header {
    background-color: transparent;
    border: none;
    position: absolute;
    width: 100%;
  }

  .card-image {
    height: 210px;
    object-fit: cover;
    object-position: center;
    background-image: url("../images/item_img.svg");
    background-size: cover;
    background-position: center;

    img {
      width: 100%;
      pointer-events: none;
    }

    @include media-breakpoint-down("lg") {
      // height: 116px;
    }

    @include media-breakpoint-down("sm") {
      // height: 90px;
    }
  }

  .card-body {
    background: linear-gradient(270deg, #4b597e 0%, #1f2639 100%);
    color: #fff;
  }

  .profile-pic {
    @include profile-pic;
  }
}

.tag {
  @include tag;
}

img.icon {
  width: 15px;
  object-fit: contain;
  object-position: center;
}

.bi-inline {
  font-size: 20px;
  color: $color !important;
}

#detail {
  // background-color: $bg-color-2;
  // border-radius: 5px;

  .fixed-offer-btn {
    min-width: 200px;
    width: 100%;
    padding: 10px 6px;

    @include media-breakpoint-down("sm") {
      position: fixed;
      bottom: 10px;
      z-index: 5;
      left: 0;
      // background-color: #61AD89 !important;
      // color: #fff !important;
      width: 95%;
      margin-left: 2.5%;
      padding: 10px;
    }
  }

  .detail-cols {
    column-gap: 15px;

    .detail-col-1 {
      width: 28%;
      max-width: 350px;

      @include media-breakpoint-down("lg") {
        width: 100%;
        min-width: 300px;
        max-width: none;
      }
    }

    .detail-col-2 {
      width: 50%;
      // max-width: 700px;

      @include media-breakpoint-down("lg") {
        width: 100%;
      }
    }

    .detail-col-3 {
      width: 22%;

      @include media-breakpoint-down("lg") {
        width: 100%;
      }
    }
  }

  svg.icon.element-icon {
    width: 60px;
    height: 60px;
  }

  .fixed-fill-price-btn {
    @include media-breakpoint-down("sm") {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 5;
    }
  }

  .bi {
    color: $icon-color;
    font-size: 13px;
  }

  .lock {
    color: #ffe600;

    .icon {
      fill: #ffe600;
      width: 12px;
    }

    span {
      font-size: 12px;
    }
  }

  .flip-card {
    border: 1px solid rgba($border-color, 0.5);
    border-radius: 0;
    padding: 20px 14px;
    cursor: pointer;
    color: #fff;
    background: linear-gradient(180deg, $color 0%, #20273a 148.48%);

    .card-image {
      img {
        width: 100%;
        height: 360px;
        object-fit: cover;
        object-position: center;
      }
    }

    .card-input {
      padding: 8px 0;
    }

    .card-input-text {
      background-color: transparent !important;
      border: none !important;
    }

    .price {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 20px;
    }

    .attribute {
      max-width: 145px;
    }

    .title,
    .attribute-value {
      font-family: Bebas Neue;
      font-size: 25px;
    }

    .attribute-value {
      color: #00b5ff;
      // font-weight: bold;
    }
  }

  .card.flip-card {
    height: 567px;
  }

  .flip-card-body {
    background-color: transparent !important;
    border: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .feature-card {
    padding: 8px;
    background-color: transparent;
    border: 1px solid $color;
    border-radius: 5px;
    margin-right: 8px;
    margin-bottom: 8px;
    height: 40px;
    font-size: 12px;
  }

  .detail {
    padding: 20px 0;
  }

  .card {
    border-radius: 5px;

    .icon {
      height: 24px;
    }
  }

  .cancel {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    min-width: 77px;
  }

  .enter {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  @media (min-width: 1200px) and (max-width: 1271px) {

    .cancel,
    .enter {
      font-size: 15px;
      height: 38px;
    }
  }

  @include media-breakpoint-between("lg", "xl") {

    .cancel,
    .enter {
      font-size: 12px;
      height: 38px;
      padding-top: 9px;
    }

    .message {
      font-size: 11px;
    }
  }
}

.bidding-complete {
  .btn {
    width: 200px;
    margin-bottom: 20px;
  }
}

.selling-price-confirmation {
  width: auto;
  background-color: transparent;
  border-radius: 5px;
  margin: 0px 65px 30px;

  @include media-breakpoint-down("xxxl") {
    margin: 20px 30px 35px;
  }

  @include media-breakpoint-down("sm") {
    padding: 15px;
    margin: 0px;
    margin-top: 20px;
  }

  .description {
    span {
      z-index: 99;
      word-break: break-word;
    }
  }

  .card {
    border: 1px solid #fff;
    border-radius: 5px !important;
    background: transparent;
    padding: 14px;
  }

  .btns {
    margin-top: 53px !important;
  }
}

.login {
  height: $heightWithoutNav;
  background: linear-gradient(90deg, $bg-color 50%, $bg-card-color 50%);
  @include flex-center;

  .login-card {
    display: flex;
    flex-direction: row;
    width: 70%;
    max-width: 1200px;
    justify-content: center;
    align-items: center;
    background-color: $bg-color;
    box-shadow: 5px 0px 11px #00000025;
    padding: 47px;

    .btn {
      min-width: 250px;
    }

    &.default {
      width: unset;
      padding: unset;
    }

    @include media-breakpoint-down("sm") {
      width: 95%;
      padding: 15px;
    }

    .content {
      width: 100%;
      max-width: 400px;
      height: 500px;

      .login-bg {
        background-image: url("../images/login_bg.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .card {
        padding: 30px 30px;
      }

      .login-bg,
      .card {
        width: 400px;
        height: 100%;

        @include media-breakpoint-down("lg") {
          width: 380px;
        }
      }

      .anicana-logo {
        width: 100px;
      }
    }
  }

  .box {
    // height: 100vh;
    border: none;
    width: 100%;
    max-width: 450px;
  }

  form {
    width: 100%;
    max-width: 400px;
    @include flex-center;
    flex-direction: column;
  }

  .forgot-text {
    @include media-breakpoint-down("sm") {
      font-size: 14px;
    }
  }
}

.register {
  height: $heightWithoutNav;

  .box {
    border: none;
    width: 100%;
    // max-width: 700px;

    .wizard {
      width: 500px;
      max-width: 90vw;
    }
  }
}

.modal-box {
  // border: 3px solid $color;
  background: $bg-color;
  border-radius: 15px;
  overflow: auto;
  border-radius: 15px;
  outline: none;
  padding: 20px;
  width: 100%;
  max-width: 500px;
  color: #fff !important;
  background: $modal-bg-color !important;

  &-bordered {
    border: 3px solid $color !important;
    border-radius: 15px !important;
    color: #fff !important;
    background: $modal-bg-color !important;
    padding: 45px;
    width: 660px;
    height: 398px;
    max-width: 95%;

    @include media-breakpoint-down("xxxl") {
      padding: 20px;
      width: 500px;
    }

    &:has(.error-icon) {
      button:has(.close-icon) {
        display: none !important;
        pointer-events: none;
        cursor: default;
      }

      .modal-title {
        font-weight: normal !important;
        margin-bottom: 13px;
        margin-top: 55px !important;
      }

      .modal-text {
        margin-bottom: 51px;
        font-size: 22px !important;

        @include media-breakpoint-down("xxxl") {
          margin-bottom: 30px;
        }
      }
    }

    button:has(.close-icon) {
      position: absolute;
      top: 35px;
      right: 43px;

      @include media-breakpoint-down("xxxl") {
        top: 8px;
        right: 10px;
      }
    }
  }
}

.modal-content {
  // min-width: 375px;
  max-width: 660px;
}

.modal-title {
  // @extend .header;
  font-size: 25px;
  @extend .fw-700;
  margin-bottom: 63px;
  padding-top: 0;
}

.modal-text {
  font-size: 20px !important;
  @extend .fw-400;
  margin: 0;
  margin-bottom: 0 !important;
  overflow: unset;
}

.break-word {
  overflow-wrap: break-word;
}

.float-center {
  @include float-center;
}

.term-policy {
  .policy-card {
    min-width: 500px;
    width: 25%;
    height: 480px;
    border-radius: 20px;
    background-color: $bg-card-color;
    border: none;
    box-shadow: 1px 1px 5px rgba($border-color, 0.7);

    @include media-breakpoint-down("sm") {
      width: 100%;
    }
  }

  .custom-check-box {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: none;
    /* Not removed via appearance */
    margin-top: 4px;

    // uncheck styles
    font: inherit;
    color: currentColor;
    width: 1.3em;
    height: 1.3em;
    border: 0.15em solid currentColor;
    border-radius: 0.15em;
    transform: translateY(-0.075em);

    // check vs uncheck state
    display: grid;
    place-content: center;
  }

  .custom-check-box::before {
    content: "";
    width: 0.75em;
    height: 0.75em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: CanvasText;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  }

  .custom-check-box:checked::before {
    transform: scale(1);
  }

  .custom-check-box-in-search {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: none;
    /* Not removed via appearance */
    margin-top: 4px;

    // uncheck styles
    font: inherit;
    color: currentColor;
    width: 1.3em;
    height: 1.3em;
    border: 0.15em solid currentColor;
    border-radius: 0.15em;
    transform: translateY(-0.075em);

    // check vs uncheck state
    display: grid;
    place-content: center;
  }

  .custom-check-box::before {
    content: "";
    width: 0.75em;
    height: 0.75em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: CanvasText;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  }

  .custom-check-box:checked::before {
    transform: scale(1);
  }

  .next-btn {
    padding: 10px 0px 10px 0px;
    width: 60%;
    text-align: center;
    background-color: #61ad89;
    color: #fff;
    border: none;
    border-radius: 5px;
  }
}

.element-check-box {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: none;
  /* Not removed via appearance */
  margin-top: 6px;

  // uncheck styles
  font: inherit;
  color: currentColor;
  width: 20px;
  height: 20px;
  border: 0.1em solid currentColor;
  border-radius: 0.3em;
  transform: translateY(-0.075em);

  // check vs uncheck state
  display: grid;
  place-content: center;
}

.element-check-box::before {
  content: "";
  width: 0.75em;
  height: 0.75em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em $btn-active-color;
  border: 0.1em solid currentColor;
  /* Windows High Contrast Mode */
  background-color: CanvasText;
  transform-origin: bottom left;
  // clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  clip-path: polygon(17% 54%,
      28% 43%,
      38% 54%,
      70% 22%,
      81% 33%,
      38% 75%,
      17% 54%);
}

.element-check-box:checked::before {
  transform: scale(1);
}

.element-check-box:focus {
  border: 0.1em solid $btn-active-color;
  // outline: max(2px, 0.15em) solid currentColor;
  // outline-offset: max(2px, 0.15em);
}

.card {
  &.number-card {
    width: 100%;
    height: 480px;
    border-radius: 20px;
    background-color: $bg-card-color;
    border: none;
    box-shadow: 1px 1px 5px rgba($border-color, 0.7);

    .btn {
      width: 100%;
      max-width: 250px;
      height: 50px;
    }

    &.default {
      background-color: $default-color;
    }

    .number {
      @include number-circle;
      position: absolute;
    }

    .card-body {
      padding-top: 126px;

      .stick-bottom {
        position: absolute;
        bottom: 50px;
        width: 100%;
        left: 0;
        @include flex-center;
      }
    }
  }
}

.stepper-container {
  width: 100%;
  max-width: 800px;

  a,
  span {
    font-size: 15px !important;
  }

  & div div div div {

    a,
    span,
    div {
      line-height: 58px !important;
      font-size: 15px !important;
      font-weight: 600 !important;
    }

    div {
      color: $color !important;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .circle {
      width: 40px;
      height: 30px;
      background: $default-color;
      color: #fff;
      border-radius: 50px;
      @include flex-center;

      &.active {
        width: 100px;
        background: $color;
      }

      &.complete {
        background: $btn-color-2;
      }
    }

    .text {
      font-size: 12px !important;
      font-weight: 500;
    }
  }
}

.account {
  .profile-pic {
    width: 140px;
    height: 140px;
  }
}

.custom-popover {
  margin-right: 0px !important;
  padding-right: 0px !important;
}

.popover-container {
  z-index: 9999;

  @include media-breakpoint-down("lg") {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  @include media-breakpoint-up("lg") {
    padding: 0px;
    position: absolute;
    top: $navHeight;
    right: 0px;
    width: 350px;

    &.noti {
      width: 458px;
    }
  }

  .body {
    background: linear-gradient(180deg, $color 0%, #21283c 100%);
    color: #fff;
    border-radius: 0px;
    padding: 25px 20px;
    height: $heightWithoutNav;
    overflow: scroll;

    @include media-breakpoint-down("lg") {
      height: 100vh;
    }
  }

  &.noti {
    .body {
      height: auto;
      max-height: $heightWithoutNav;

      @include media-breakpoint-down("lg") {
        height: 100%;
        max-height: 100%;
      }
    }

    .pill {
      background-color: #fff;
      border-radius: 5px;
      color: $color;
      padding: 0 5px;

      .notice {
        font-size: 15px;
      }
    }

    .date {
      font-size: 17px;
    }

    p {
      font-size: 15px;
    }
  }

  &.search-popover {
    height: $navHeight;

    .body {
      background: none;
      height: $navHeight;
      padding: 0;
    }
  }

  .accordion {
    background-color: transparent !important;
    padding: 0px;

    .accordion-item {
      background-color: transparent !important;
      color: #fff;
      border: none;

      a.accordion-button {
        background-color: transparent !important;
        color: #fff;
        color: inherit !important;
        text-decoration: none;
        box-shadow: none;
        padding: 8px 20px;

        &.collapsed::after {
          background-image: url(../icons/chevron_down.svg);
          transform: rotate(-90deg);
        }

        &::after {
          background-image: url(../icons/chevron_down.svg);
          background-size: 10px;
          background-position: center;
          transform: none;
        }
      }

      .accordion-body {
        padding: 5px 20px;

        ul {
          list-style: none;
          margin-bottom: 8px;

          li {
            cursor: pointer;
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  .logout {
    // position: absolute;
    // bottom: 30px;
    margin-top: 30px;

    @include media-breakpoint-down("lg") {
      bottom: 15px;
    }

    @media screen and (max-height: 700px) {
      position: relative;
      bottom: 0px;
    }
  }
}

.guide {
  margin-bottom: 200px;

  .card {
    height: 450px;
    position: relative;
    border: 3px solid $color;
    border-radius: 0;

    &.active {
      border: none;

      .card-image {
        border-radius: 0;
      }

      .card-body {
        width: 100%;
        margin-left: unset;
        margin-bottom: unset;
        border-radius: 0px 0px 5px 5px;
      }
    }

    &:hover {
      &.pointer {
        box-shadow: 4px 4px 15px rgb(0 0 0 / 25%);
      }
    }

    @include media-breakpoint-down("sm") {
      height: 400px;
    }

    .card-image {
      width: 100%;
      height: 100%;
      background: #fff;

      .coming-soon {
        @include coming-soon-text($color, false);
        padding-bottom: 110px;
        pointer-events: none;
      }
    }

    .card-body {
      width: calc(100% + 2px);
      height: 150px;
      max-height: 150px;
      background: $color;
      position: absolute;
      bottom: 0;
      margin-left: -1px;
      margin-bottom: -1px;
      @include flex-center;

      p {
        font-size: 26px;
        font-weight: 700;
        margin-bottom: 0;

        @include media-breakpoint-down("xxl") {
          font-size: 20px;
        }
      }
    }
  }

  .accordion {
    .accordion-item {
      color: #000;

      a.accordion-button {
        color: #000;

        &::after {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        }
      }
    }
  }
}

.guide-image {
  max-width: 1000px;
  border: 2px solid $color;
  border-radius: 10px;
}

.nav-tabs,
.nav-pills {
  // border-bottom: none;
  // background-color: transparent;
  display: flex;
  justify-content: space-between;

  .nav-item {
    max-width: 150px;

    .nav-link {
      // color: $color;
      // border-radius: 0;
      // border-bottom: transparent;

      &:not(.active) {
        background-color: none;
        color: $disable-color;
        border: 1px solid $default-color;
      }

      &:hover {
        background-color: none;
      }

      &.active {
        background-color: $color;
        color: #fff;
        border: 1px solid $color;
      }

      &:hover {
        // border: none;
        // border-bottom: 5px solid $tab-color;
        // background-color: transparent;
      }
    }

    &:hover {
      border: none;
    }
  }

  &.nav-fill-transparent {
    .nav-item {
      .nav-link {
        &:not(.active) {
          background-color: $bg-color;
          color: rgba($color, 0.5);
          border: 1px solid rgba($color, 0.5);
        }

        &:hover,
        &.active {
          background-color: $color;
          border: 1px solid rgba($color, 0.5);
          // border: 1px solid $btn-active-color;
          color: $bg-color;
        }
      }
    }
  }
}

.guide .nav-pills {
  border: 1px solid $border-color;
  border-radius: 15px;
  min-height: 480px;
  padding: 20px;

  .nav-link {
    color: $color;
    border-radius: 0;

    &.active,
    &:hover {
      border: none;
      background-color: transparent;
      color: $color;
    }

    &.active {
      font-weight: bold;
    }
  }
}

.tab-content {
  &.vertical {
    padding-left: 60px;

    @include media-breakpoint-down("sm") {
      padding-left: inherit;
    }
  }
}

.footer {
  .title {
    // font-size: 1.25rem;
    // color: red;
    font-size: 25px;
  }

  .content {
    text-align: center;
    // max-width: 700px;
    @include float-center;

    .footer-logo {
      margin-top: 50px;
      margin-bottom: 60px;
      width: 25rem;
      height: auto;

      @include media-breakpoint-down("xxlg") {
        width: 186px;
        margin-top: 40px;
        margin-bottom: 40px;
      }
    }

    .copyright {
      @include media-breakpoint-down("sm") {
        margin-bottom: 60px;
      }
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-down("sm") {
      flex-direction: column;

      a {
        margin-bottom: 15px;
      }
    }
  }
}

.bookmark-item,
.history-item {
  background-color: $color;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
  color: #fff;

  img {
    width: 120px;
    height: 120px;
    object-fit: cover;
    object-position: center;

    @include media-breakpoint-down("sm") {
      width: 100%;
      height: 200px;
    }
  }

  // .icon {
  //     position: absolute;
  //     left: 15px;
  //     top: 15px;
  // }

  .tag {
    position: relative;
    font-weight: bold;
    color: $color;
    font-size: 12px;
    border-radius: 5px;
    padding: 0 5px;
    background-color: $btn-color-2;
  }
}

.box {
  border: 1px solid $color;
  border-radius: 10px;
  padding: 15px;

  .image-container {
    width: 100%;
    height: 120px;
    background-color: $default-color;

    img {
      width: 100%;
      height: 120px;
      object-fit: contain;
      object-position: center;
    }
  }
}

ul {
  li {
    margin-bottom: 12px;
  }
}

label {
  display: flex;
  align-items: center;
  flex-direction: row;

  span {
    width: 300px;
    max-width: 60vw;
  }
}

ul.pagination {
  li.page-item {
    border: none;

    .page-link {
      background-color: transparent;
      color: $color;
      border: none;
    }

    .page-link:focus {
      box-shadow: none;
    }

    &.active .page-link {
      font-size: 32px;
      text-decoration: none;
      margin-top: -15px;
    }

    .page-number {
      text-decoration: underline;
    }
  }

  li.page-item-disable {
    display: none;
  }
}

// phase-2
#home {
  .feature {
    background-color: $bg-color-2;
  }

  .p-faq {
    padding: 0 20% 0;
  }

  @include media-breakpoint-down("md") {
    .p-faq {
      padding: 0 0 0;
    }
  }
}

.bg-global {
  position: relative;
  min-height: 300px;
}

.bg-global:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/global.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 300px;
  max-height: 837px;
}

.error-icon {
  background-image: url("../images/error.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 57px;
  height: 57px;
  margin-bottom: 59px;
}

.success-icon {
  background-image: url("../images/success.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 57px;
  height: 57px;
  margin-bottom: 59px;
}

.close-icon {
  background-image: url("../icons/close_white.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 26px;
  height: 26px;
}

.content {
  position: relative;
}

.indicator-below .control-dots {
  // bottom: -40px;

  .dot {
    border: 1px solid $color;

    &.selected {
      background-color: $color;
    }
  }
}

.disabled {
  pointer-events: none;
}

.text-rainbow {
  @include text-rainbow;
}

.text-break {
  word-break: break-all;
}

.text-overflow {
  @include text-overflow;
}

#faqs {
  .accordion {
    padding: 0;

    .accordion-item {
      background-color: $bg-color-2 !important;
      border: none;

      .accordion-header {
        .accordion-button {
          background-color: $bg-color-2;
          color: $color;
          font-weight: bold;
          font-size: 20px;
          box-shadow: none;
          border-radius: 0;

          &::after {
            background-image: url("../icons/chevron_right_bg.svg");
            fill: $color;
            transform: rotate(0deg) scale(0.75);
          }

          &:not(.collapsed)::after {
            transform: rotate(90deg) scale(0.75);
          }
        }
      }

      .accordion-body {
        color: $color;
        font-size: 20px;
      }
    }
  }
}

#information {
  .nav-pills {
    .nav-item {
      min-width: fit-content;

      .nav-link {
        padding: 8px 36px;
      }
    }
  }

  .pill {
    background-color: $color;
    border-radius: 5px;
    color: $bg-color;
    padding: 8px 40px;
  }

  img {
    &.info_pic {
      width: 100% !important;
      height: 350px;
      object-fit: cover;
      object-position: center;
    }
  }
}

.datepicker {
  @include textbox;
  max-width: 185px;
  height: 33px;
  text-indent: 0;
}

.textbox {
  @include textbox;
}

.textbox-2 {
  @include textbox($bg-color, #fff);
}

.textbox-3 {
  @include textbox($bg-color-2, $bg-color-2);
  color: $color !important;
}

.textbox,
.textbox-2,
.textbox-3 {
  &.price {
    width: 100%;
    height: auto;
    border: 1px solid $color;
    background-color: $color !important;
    color: #fff;
    text-align: center;
    border-radius: 0;
  }
}

.phoneInput {
  .regionbtn {
    @include textbox;
  }
}

.textarea {
  height: 150px;
  padding-top: 8px;
}

.card-line-header {
  p {
    -ms-flex: 0 0 250px;
    flex: 0 0 fit-content;

    &.title {
      font-size: 20px !important;
    }
  }

  .divider {
    width: 100%;
    margin-bottom: 26px;
    border-color: $color !important;

    &.dim {
      border-color: #ffffff25 !important;
    }
  }
}

.price-range {
  .textbox {
    height: 38px;
  }

  p {
    margin-top: 12px;
    padding-right: 25px;
  }
}

.gradient {
  @include gradient-border;
}

.gradient-text-hover {
  @include gradient-text-hover;
}

.filter {
  .filter-accordion-body {
    padding-left: 20px;
    margin-top: 16px;

    ul {
      &.nav-pills {
        li.nav-item {
          margin-right: 15px;

          a.nav-link {
            background-color: none;
            color: $bg-color !important;
            // padding: 0 15px !important;
            text-indent: 0;
            border: none;

            &.active {
              // background-color: $btn-active-color !important;
              border: 2px solid $btn-active-color;
              color: $btn-active-color !important;

              a.nav-link {
                color: $btn-active-color !important;
              }
            }
          }
        }
      }
    }

    &.minmaxrow {
      input {
        width: 90px;
        height: 33px !important;
        font-size: 15px;
        padding-top: 3px;
      }
    }
  }
}

.category-carousel {
  position: relative;

  svg,
  img {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  img {
    @include media-breakpoint-up("xl") {
      height: 500px;
    }

    @include media-breakpoint-up("xxl") {
      height: 700px;
    }
  }

  .coming-soon {
    @include coming-soon-text;
  }
}

.first-view-mobile-crousel {
  overflow: hidden;

  .img {
    position: relative;
    text-align: center;
    color: white;
  }

  .opacity-transition {
    visibility: visible;
    animation: fade-in 1s;
  }

  @keyframes fade-in {
    0% {
      opacity: 0.3;
    }

    100% {
      opacity: 1;
    }
  }

  .coming-soon-text {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }

  .slide-left {
    .coming-soon-text {
      display: none;
    }

    position: relative;
    animation: slide-left-move 2s;
  }

  .slide-right {
    .coming-soon-text {
      display: none;
    }

    position: relative;
    animation: slide-right-move 2s;
  }

  @keyframes slide-left-move {
    0% {
      left: 0px;
    }

    100% {
      left: 800px;
    }
  }

  @keyframes slide-right-move {
    0% {
      right: 0px;
    }

    100% {
      right: 800px;
    }
  }
}

.privacy,
.terms {
  p {
    // text-indent: 30px;
    padding-left: 30px;
    text-align: justify;

    &.break {
      margin-bottom: 100px;
    }
  }

  ol {
    li {
      p {
        // text-indent: 30px !important;
        padding-left: 30px;
      }

      &.level-2::marker {
        content: "(" counter(list-item) ")";
      }
    }

    &.katakana {
      list-style-type: katakana;
    }
  }

  ol,
  ul {
    &.square {
      list-style-type: square;

      li {
        &::marker {
          font-size: 2rem;
        }

        .disc li::marker {
          font-size: unset;
        }
      }
    }

    &.disc {
      list-style-type: disc;

      p {
        text-indent: 0;
      }
    }
  }
}

.terms {
  h5 {
    margin-top: 50px;
  }

  p {
    text-indent: 0;
  }
}

.swal2-container {
  .swal2-icon {
    border: none;
  }

  .swal2-html-container {
    overflow: unset;
    font-size: 22px;
  }

  .swal2-actions {
    margin: unset;
  }
}

.react-tel-input {
  .selected-flag {
    width: 100%;
    padding: 0;
    @include flex-center;
  }
}

.flexible-textbox {
  &:focus {
    border-color: transparent;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0) inset, 0 0 8px rgba(126, 239, 104, 0);
    outline: 0 none;
  }
}

.fix-max-width {
  max-width: $maxWidth;
}

.navLoginBtn {
  margin-left: 60px;

  @include media-breakpoint-down("sm") {
    margin-left: 10px;
  }
}

.abilities {
  display: flex;
  width: "100%";
  justify-content: space-between;

  .fake_input {
    border: 1px solid $color;
    border-radius: 5px;
    width: 48%;
    height: 47px;
  }
}

.persona-star-container {
  background-color: #232a44;
  width: 159;
  height: 31;
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 10px;

  .persona-star-item {
    width: 15px;
    height: 15px;
    margin-right: 8px;
  }

  .star-contain {
    background-color: #fff;
  }
}

.change-btn {
  &:hover {
    cursor: pointer;
  }
}

.form-check-input {
  background-color: transparent;

  &checkmark {
    background-color: red;
  }
}

.custom-check {
  border-color: $btn-active-color;
}

.cutstom-filter-nav {
  position: absolute;
  width: 100%;
  top: 57px;
  z-index: 22;
  background-color: $bg-color;
}

textarea:focus,
select:focus,
.sort:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.phoneInput input:focus-visible,
.uneditable-input:focus {
  border-color: transparent;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0) inset, 0 0 8px rgba(126, 239, 104, 0);
  outline: 0 none;
}

.pre-wrap {
  white-space: pre-wrap !important;
}

@keyframes cf1FadeInOut {
  0% {
    opacity: 1;
  }

  45% {
    opacity: 1;
  }

  55% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.child-smoothable-transition {
  * {
    transition: all .5s !important;
    user-select: none !important;
  }
}

.maintenance-mode {
  width: 100%;
  height: 100%;

  .text {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #495376;
    text-align: center;

    @include media-breakpoint-down("sm") {
      font-size: 14px;
    }
  }

  .title {
    position: absolute;
    top: 17.91%;
    font-weight: 700;
    font-size: 40px;

    @include media-breakpoint-down("sm") {
      top: 12.75%;
      font-size: 25px;
    }
  }

  .img {
    position: absolute;
    top: 32.68%;
  }

  .description {
    position: absolute;
    top: 59.53%;

    @include media-breakpoint-down("sm") {
      top: 53.29%;
    }
  }

  .footer {
    position: absolute;
    bottom: 2.6%;

    @include media-breakpoint-down("sm") {
      bottom: 3.13%;
      font-size: 15px;
    }
  }
}

.ReactModal__Overlay,
.swal2-container {
  z-index: 10000 !important;
}

.amount-box-container {
  @include textbox;
  border-style: solid;
  color: $color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 28px;
  position: relative;
  flex-grow: 1;
  height: 40px;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  max-width: 520px;

  .amount-box-title {
    position: absolute;
    left: 12px;
    top: -14px;
    background-color: $bg-color;
    padding: 0px 3px;
    color: $color;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;

    &.jp-title {
      top: -10px;
    }
  }
}

@media (min-width: 768px) {
  .custom-col-md-4 {
    flex: 0 0 auto;
    width: 32% !important; /* Set the width to 32% */
  }
}

.receipt {
  width: 100%;
  max-width: 1141px;
  height: auto;
  border: 1px solid $color;
  background-color: #F5F8FF;
  border-radius: 5px;
  padding: 29px 41px 52px 37px ;
  font-size: 15px;

  &_image {
    margin-top: -44px;
    img {
      width: 257px;
      height: 286px;
      object-fit: cover;
    }
  }

  .title, .amount {
    font-weight: 500; // medium
    color: #495376;
  }

  .title {
    font-size: 30px;
    width: '100%';
  }

  .name {
    font-size: 18px;
    padding-top: 32px;
    margin-top: 43px;
  }

  .name_box {
    width: 309px;
    max-width: 100%;
    border: none;
    border-bottom: 1.5px dashed #495376 !important;
    height: 36px;
    background-color: transparent;
    color: #495376;
  }

  .date {
    font-size: 15px;
    margin-top: 40px;

    &-wrapper {
      @include media-breakpoint-up('md') {
        width: 210px;
        float: right;
      }
    }
  }

  .description {
    margin-top: 89px;
  }

  .sub_description {
    margin-top: 33px;
  }

  .footer {
    margin-top: 57px;
  }

  .amount {
    font-size: 25px;
  }

  @include media-breakpoint-down('md') {
    padding: 30px 15px;

    &_image {
      margin-top: 23px;
    }

    .title {
      font-size: 25px;
      margin-top: 39px;
    }

    .name, .date {
      margin-top: 0;
      width: 100%;
    }

    .description {
      margin-top: 47px;
    }

    .sub_description {
      margin-top: 50px;
    }
  }
}

.fs {
  @include generate-font-size();

  @each $size, $value in $resbreakpoints {
    &-#{$size} {
      @include fs-res($size);
    }
  }
}

.fw {
  @include generate-font-weight();
}

.p {
  @each $position, $value in $positions {
    &-#{$position} {
      $i: 0;

      @while $i <=$spacing_limit {
        &-#{$i} {
          @include assign-spacing("padding", $position, $i);
          $i: $i + 1;
        }
      }

      @each $i in $spacing_includes {
        &-#{$i} {
          @include assign-spacing("padding", $position, $i);
        }
      }
    }
  }

  @each $position, $value in $positions {
    &-#{$position} {
      @each $size, $spacingbreakpoint in $spacingbreakpoints {
        &-#{$size} {
          @include media-breakpoint-up($size) {
            $i: 0;

            @while $i <=$spacing_limit {
              &-#{$i} {
                @include assign-spacing("padding", $position, $i);
                $i: $i + 1;
              }
            }

            @each $i in $spacing_includes {
              &-#{$i} {
                @include assign-spacing("padding", $position, $i);
              }
            }
          }
        }
      }
    }
  }
}

.m {
  @each $position, $value in $positions {
    &-#{$position} {
      $i: -$spacing_limit;

      @while $i <=$spacing_limit {
        &-#{$i} {
          @include assign-spacing("margin", $position, $i);
          $i: $i + 1;
        }
      }

      @each $i in $spacing_includes {
        &-#{$i} {
          @include assign-spacing("margin", $position, $i);
        }
      }

      @each $i in $spacing_includes {
        &--#{$i} {
          @include assign-spacing("margin", $position, -$i);
        }
      }
    }
  }

  @each $position, $value in $positions {
    &-#{$position} {
      @each $size, $spacingbreakpoint in $spacingbreakpoints {
        &-#{$size} {
          @include media-breakpoint-up($size) {
            $i: -$spacing_limit;

            @while $i <=$spacing_limit {
              &-#{$i} {
                @include assign-spacing("margin", $position, $i);
                $i: $i + 1;
              }
            }

            @each $i in $spacing_includes {
              &-#{$i} {
                @include assign-spacing("margin", $position, $i);
              }
            }

            @each $i in $spacing_includes {
              &--#{$i} {
                @include assign-spacing("margin", $position, -$i);
              }
            }
          }
        }
      }
    }
  }
}