@import "color.scss";
@import "breakpoint.scss";
@import "common.scss";

#mypage {
  #wrapper {
    padding-left: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  #wrapper.toggled {
    padding-left: 300px;
  }

  #sidebar-wrapper {
    z-index: 1000;
    position: fixed;
    left: 300px;
    width: 0;
    height: 100%;
    margin-left: -300px;
    overflow-y: auto;
    background: #fff;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  #sidebar-wrapper {
    box-shadow: inset -1px 0px 0px 0px #dddddd;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 300px;
  }

  #page-content-wrapper {
    width: 100%;
    position: sticky;
    padding: 15px;
  }

  #wrapper.toggled #page-content-wrapper {
    position: sticky;
    margin-right: -300px;
  }

  /* Sidebar Styles */

  .sidebar-col {
    -ms-flex: 0 0 330px;
    flex: 0 0 330px;
    background: linear-gradient(180deg, #4b597f 0%, #1f2639 100%);
    z-index: 8889;
    
    p {
      font-size: 20px;
    }
  }

  .mypage-wrapper {
    .side-content {
      // background-color: $bg-color-2  !important;
      padding: 10px;
      overflow: auto;
    }

    .sidebar-nav {
      height: 100vh; //$mypageSidebarHeight;
    }
  }

  .sidebar-container {
    position: relative;
    z-index: 8888;
    
    height: 100%;
    overflow: hidden;
    margin-top: 0px;
  }
  .sidebar-nav {
    position: fixed;
    top: $navHeight;
    width: 330px;
    height: calc($heightWithoutNav - 70px); //$sidebarHeight;
    margin: 0;
    padding: 0 20px;
    list-style: none;
    // border-right: 1px solid $border-color;
    padding-bottom: 1px;
    overflow: scroll;
    z-index: 3;
    
    @include media-breakpoint-down('xl') {
      top: 64px;
      height: calc(100vh - 134px);
    }
    
    @include media-breakpoint-down('lg') {
      background: linear-gradient(180deg, #4b597f 0%, #1f2639 100%);
    }

    z-index: 3;

    li {
      color: $bg-color;
    }

    .icon {
      fill: $bg-color;
      width: 14px;
    }

    .subitem {
      .icon {
        width: 17px;
      }
    }
  }

  .sidebar-nav li {
    // text-indent: 15px;
  }

  .sidebar-nav li.last {
    margin-bottom: 100px;
  }

  .sidebar-nav li.category {
    text-indent: 15px;
    margin-bottom: 30px;
  }

  .sidebar-nav li.group {
    margin-bottom: 30px;

    p {
      font-weight: regular;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        // border-bottom: 1px solid $border-color;
        text-indent: 15px;
      }

      li.active {
        background-color: $bg-li-color;
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
      }

      li:not(.group) {
        // margin-bottom: 0px;
        // height: 40px;
        display: flex;
        flex-direction: column;
        // align-items: center;
        height: auto;
        margin-bottom: 10px;
      }
    }
  }

  .sidebar-nav ul.ul-row {
    display: -webkit-box;
    overflow: auto;
  }

  .sidebar-nav li.group-row {
    border: 1px solid $border-color;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }

  .sidebar-nav li a {
    display: block;
    text-decoration: none;
    color: $bg-color;
    width: 100%;
    padding: 1px 5px;
  }

  .sidebar-nav li a:hover {
    text-decoration: none;
  }

  .sidebar-nav li a:active,
  .sidebar-nav li a:focus {
    text-decoration: none;
  }

  .sidebar-nav > .sidebar-brand {
    height: 65px;
    font-size: 18px;
    line-height: 60px;
    // margin: 20px 0 !important;
    margin: 0 !important;
    margin-bottom: 30px !important;
  }

  .sidebar-nav > .sidebar-brand a {
    color: #fff;
  }

  .sidebar-nav > .sidebar-brand a:hover {
    color: #fff;
    background: none;
  }

  @include media-breakpoint-down("sm") {
    #wrapper.toggled {
      #sidebar-wrapper,
      .sidebar-nav {
        width: 100vw;
      }
    }
  }

  @media (min-width: 768px) {
    #wrapper {
      padding-left: 300px;
    }

    #wrapper.toggled {
      padding-left: 0;
    }

    #sidebar-wrapper {
      width: 300px;
    }

    #wrapper.toggled #sidebar-wrapper {
      width: 0;
    }

    #page-content-wrapper {
      padding: 0px;
      position: relative;
    }

    #wrapper.toggled #page-content-wrapper {
      position: relative;
      margin-right: 0;
    }

    .sidebar-nav-toggler {
      display: none;
    }
  }

  // #sidebar-wrapper li.active>a:after {
  //     border-right: 17px solid #f4f3ef;
  //     border-top: 17px solid transparent;
  //     border-bottom: 17px solid transparent;
  //     content: "";
  //     display: inline-block;
  //     position: sticky;
  //     right: -1px;
  // }

  .sidebar-brand {
    padding: 18px 0px;
    margin-bottom: 20px;
  }

  .navbar .navbar-nav > li > a p {
    display: inline-block;
    margin: 0;
  }

  p {
    // font-size: 16px;
    line-height: 1.4em;
  }

  .navbar-default {
    background-color: #f4f3ef;
    border: 0px;
    border-bottom: 1px solid #dddddd;
  }

  btn-menu {
    border-radius: 3px;
    padding: 4px 12px;
    margin: 14px 5px 5px 20px;
    font-size: 14px;
    float: left;
  }
}

.filter-action {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: "100%";
  height: 50px;
  margin: 0;
  background-color: $bg-color-2;
}
