@import "color.scss";
@import "breakpoint.scss";

@mixin profile-pic($width: 30px, $height: 30px) {
  // background-color: $default-color;
  border-radius: 150px;

  img {
    width: $width;
    height: $height;
    object-fit: cover;
    object-position: center;
    // background-color: $default-color;
    border-radius: 150px;
  }

  svg {
    width: $width;
    height: $height;
    border-radius: 150px;
  }
}

@mixin tag($width: "fit-content") {
  width: $width;
  background-color: $tag-color;
  padding: 5px 10px;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin coming-soon-text($color: $color, $gradient: true) {
  position: absolute;
  top: 0;
  @include flex-center;
  width: 100%;
  height: 100%;
  background-color: #ffffff80;

  span {
    font-size: 55px;
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;
    position: absolute;
    transform: rotate(45deg);
    color: $color;

    @if $gradient {
      @include text-rainbow;
    } @else {
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    width: max-content !important;

    @include media-breakpoint-down("sm") {
      font-size: 32px !important;
    }

    @include media-breakpoint-between("md", "lg") {
      font-size: 32px !important;
    }

    @include media-breakpoint-between("lg", "xl") {
      font-size: 44px !important;
    }
  }
}

@mixin thumbnail {
  width: 80px;
  height: 80px;
  object-fit: cover;
  object-position: center;
}

@mixin btn-custom($color: $btn-color, $border: $color, $borderWidth: 1px) {
  background-color: $color !important;
  border-color: $border !important;
  border-radius: 5px;
  border-width: $borderWidth;
  // min-width: 250px;

  &.btn-hover:not(:hover) {
    color: $color !important;
    background-color: transparent !important;
    border-color: $border !important;
  }
}

@mixin textbox(
  $backgroundColor: $bg-color,
  $border: $color,
  $borderWidth: 1px
) {
  background-color: $backgroundColor !important;
  border-color: $border !important;
  border-radius: 5px;
  border-width: $borderWidth;
  height: 50px;
  padding-top: 0;
}

@mixin number-circle($size: 60px, $bg-color: $color, $color: #fff) {
  width: $size;
  height: $size;
  background-color: $bg-color;
  color: $color;
  font-size: 24px;
  @include flex-center();
  border-radius: 60px;
}

@mixin float-center {
  float: none;
  margin-left: auto;
  margin-right: auto;
}

@mixin text-rainbow {
  background: linear-gradient(
    88.63deg,
    #ff0000 9.84%,
    #ff5c00 21.56%,
    #fff800 31.54%,
    #00ff0a 45.86%,
    #00e0ff 58.01%,
    #ad00ff 71.03%,
    #ff0099 87.09%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  width: fit-content;
}

@mixin text-overflow($width: auto) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: $width;
}

@mixin gradient-icon-hover($img, $imgHover, $color: $color) {
  position: relative;
  width: 100px;
  height: 100px;

  /* Images containers */
  &:before,
  &:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    display: block;
    background-size: 26px 26px;
    background-repeat: no-repeat;
    transition: opacity 1s;
  }

  /* Attach backgrounds */
  &:before {
    background-image: $img;
    opacity: 1;
  }

  &:after {
    background-image: $imgHover;
    opacity: 0;
  }

  /* Transition on hover */
  &:hover:before,
  &.hover::before {
    opacity: 0;
  }

  &:hover:after,
  &.hover:after {
    opacity: 1;
  }
}

@mixin gradient-icon-hover2($img, $imgHover, $color: $color) {
  width: 26px;
  height: 26px;
  // background-color: $color;
  // -webkit-mask-image: $img;
  // -webkit-mask-repeat: no-repeat;
  // -webkit-mask-position: center;
  // -webkit-mask-size: contain;
  // mask-image: $img;
  // mask-repeat: no-repeat;
  // mask-position: center;
  // mask-size: contain;
  background-image: $img;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  -webkit-transition: background-image 1s ease-in-out;
  -moz-transition: background-image 1s ease-in-out;
  -o-transition: background-image 1s ease-in-out;
  transition: background-image 1s ease-in-out;

  &:hover,
  &:focus,
  &.hover {
    // -webkit-mask-image: none;
    // mask-image: none;
    width: 26px;
    height: 26px;
    background-image: $imgHover;
    -webkit-transition: background-image 1s ease-in-out;
    -moz-transition: background-image 1s ease-in-out;
    -o-transition: background-image 1s ease-in-out;
    transition: background-image 1s ease-in-out;
  }
}

@mixin gradient-border() {
  position: relative;
  min-width: 175px;
  //   min-height: 400px;
  background: transparent;
  z-index: 1;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    pointer-events: none;
    transition: opacity 0.3s linear;
    background-image: url(../images/white_shadow.svg);
    background-size: cover;
    background-position: center;

    border: 5px solid;
    border-image: linear-gradient(
      140.29deg,
      #ff0000 1.38%,
      #ff8a00 12.68%,
      #fef400 20.38%,
      #9eff00 29.11%,
      #05ff00 39.82%,
      #00fff0 49.93%,
      #001aff 63.09%,
      #bd00ff 76.82%,
      #ff008a 92.71%
    );
    border-image-slice: 1;
    box-shadow: 4px 4px 15px rgb(0 0 0 / 25%);
    opacity: 0;
  }

  &:hover::before {
    opacity: 1;
  }
}

@mixin gradient-text-hover {
  background: linear-gradient(
    88.63deg,
    #ff0000 9.84%,
    #ff5c00 21.56%,
    #fff800 31.54%,
    #00ff0a 45.86%,
    #00e0ff 58.01%,
    #ad00ff 71.03%,
    #ff0099 87.09%
  );
  background-size: 100% 100%;
  animation: rainbow 1s ease-in;
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba($color, 1);
  text-align: center;
  transition: color 0.5s ease-in;

  &:hover,
  &.hover {
    color: rgba($color, 0);
  }
}

@mixin gradient-text-icon-hover($img, $imgHover, $color: $color) {
  .gradient-text {
    background: linear-gradient(
      88.63deg,
      #ff0000 9.84%,
      #ff5c00 21.56%,
      #fff800 31.54%,
      #00ff0a 45.86%,
      #00e0ff 58.01%,
      #ad00ff 71.03%,
      #ff0099 87.09%
    );
    background-size: 100% 100%;
    animation: rainbow 1s ease-in;
    background-clip: text;
    -webkit-background-clip: text;
    color: rgba($color, 1);
    text-align: center;
    transition: color 0.5s ease-in;
  }

  .gradient-icon {
    width: 26px;
    height: 26px;
    background-image: $img;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    -webkit-transition: background-image 0.3s ease-in-out;
    transition: background-image 0.5s ease-in-out;
  }

  &:hover {
    .gradient-text {
      color: rgba($color, 0);
    }

    .gradient-icon {
      background-image: $imgHover;
    }
  }
}

@mixin col-res($size: xs, $width: 20) {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
  // width: $width * 1%;
  // float: left;

  @include media-breakpoint-up($size) {
    width: $width * 1%;
    float: left;
  }
}

@mixin assign-spacing($type, $position, $i) {
  @if ($position =='x') {
      #{$type}-left: #{$i}px !important;
      #{$type}-right: #{$i}px !important;
  }

  @else if ($position =='y') {
      #{$type}-top: #{$i}px !important;
      #{$type}-bottom: #{$i}px !important;
  }

  @else if ($position =='t') {
      #{$type}-top: #{$i}px !important;
  }

  @else if ($position =='b') {
      #{$type}-bottom: #{$i}px !important;
  }

  @else if ($position =='l') {
      #{$type}-left: #{$i}px !important;
  }

  @else if ($position =='r') {
      #{$type}-right: #{$i}px !important;
  }

  @else if ($position =='around') {
      #{$type}: #{$i}px !important;
  }
}

@mixin assign-spacing-res($size, $type, $position, $i) {
  @include media-breakpoint-up($size) {
      @include assign-spacing($type, $position, $i);
  }
}

@mixin generate-font-size($from: 8, $to: 40) {
  @for $i from $from through $to {
      &-#{$i} {
          font-size: #{$i}px !important;
      }
  }
}

$fontweights: (
  100: 100,
  200: 200,
  300: 300,
  400: 400,
  500: 500,
  600: 600,
  700: 700) !default;

@mixin generate-font-weight() {

  @each $fontweight,
  $value in $fontweights {
      &-#{$fontweight} {
          font-weight: $fontweight !important;
      }
  }
}

@mixin fs-res($size, $from: 10, $to: 40) {
  @include media-breakpoint-up($size) {
      @include generate-font-size($from, $to);
  }
}

@keyframes rainbow {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 200%;
  }
}
