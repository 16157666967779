%text-overflow-2-ln {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@import "breakpoint.scss";
@import "color.scss";

.item-min-height {
  @media only screen and (min-width: 768px) {
    min-height: 370px;
  }

  @media only screen and (max-width: 768px) {
    // max-width: 175px;
    min-height: 390px;
    // display: none;
  }
}

.item-name-overflow {
  @media only screen and (min-width: 768px) {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  @media only screen and (max-width: 768px) {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.card-img {
  height: 200px;
}

// search item
.search_item {
  width: 297px;
  height: 325px;
  background-color: red;
  .image_card {
    width: 297px;
    height: 184px;
    // object-fit: cover;
    // object-position: center;
    // background-size: cover;
    // background-position: center;
    overflow: hidden;
  }
  @include media-breakpoint-down("sm") {
    width: 175px;
    height: 297px;
    margin-top: 15px;
  }
}

.Card_container {
  width: 297px;
  min-height: 325px;
  color: $bg-color;
  position: relative;
  // background-color: red;

  @include media-breakpoint-down("sm") {
    min-width: 175px;
    min-height: 297px;
  }
}
.bg_image {
  background: linear-gradient(180deg, $color 0%, #21283c 100%);
}
.img_container {
  width: 100%;
  height: 184px;
  background-color: $bg-color;

  @include media-breakpoint-down("sm") {
    min-width: 175px;
    height: 175px;
  }
}
.card_header {
  font-size: 20px;
  color: #fff;
  @include media-breakpoint-down("sm") {
    font-size: 12px;
  }
}
.card_creator {
  padding-top: 10px;
  padding-left: 10px;
  color: $bg-color;
  font-weight: 500;
  @include media-breakpoint-down("sm") {
    font-size: 13px;
    padding-left: 0px;
  }
}
.card_detail1 {
  font-size: 15px;
  font-weight: 400;
  @include media-breakpoint-down("sm") {
    font-size: 13px;
    font-weight: 300;
  }
}
.card_detail2 {
  font-size: 18px;
  @include media-breakpoint-down("sm") {
    font-size: 15px;
  }
}
