/* .App {
  text-align: center;
} */

@font-face {
  font-family: "Meiryo UI";
  src: url("./assets/fonts/MeiryoUI/a9aca7e1ecdbdf39d9a97fe080562d20.eot");
  /* IE9*/
  src: url("./assets/fonts/MeiryoUI/a9aca7e1ecdbdf39d9a97fe080562d20-iefix.eot") format("embedded-opentype"),
    /* IE6-IE8 */
    url("./assets/fonts/MeiryoUI/a9aca7e1ecdbdf39d9a97fe080562d20.woff2") format("woff2"),
    /* chrome firefox */
    url("./assets/fonts/MeiryoUI/a9aca7e1ecdbdf39d9a97fe080562d20.woff") format("woff"),
    /* chrome firefox */
    url("./assets/fonts/MeiryoUI/a9aca7e1ecdbdf39d9a97fe080562d20.ttf") format("truetype");
    /* chrome firefox opera Safari, Android, iOS 4.2+*/
    /* url("./assets/fonts/MeiryoUI/a9aca7e1ecdbdf39d9a97fe080562d20.svg#Meiryo UI") format("svg"); iOS 4.1- */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.minmaxrow .react-datepicker-wrapper {
    width: auto !important;
    text-indent: 0px !important;
}