@import "color.scss";
@import "breakpoint.scss";
@import "common.scss";

.custom_nav_container {
  position: relative;
  width: 100%;
}

.custom_filter_nav {
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: $navHeight;
  left: 0px;
  z-index: 13;
  width: 100%;
  // height: 130px;
  background-color: $bg-color;
  box-shadow: 0px 4px 4px rgba($color, 0.25);
  padding-top: 30px;
  padding-left: 350px;
  padding-right: 40px;

  @include media-breakpoint-down('xl') {
    top: 64px;
  }
}

.list_container {
  margin-top: 150px;

  @include media-breakpoint-down("lg") {
    margin-top: 80px;
  }

  @include media-breakpoint-down("ll") {
    margin-top: 70px;
  }

  @include media-breakpoint-down("md") {
    margin-top: 60px;
  }

  @include media-breakpoint-down("sm") {
    margin-top: 50px;
  }
}