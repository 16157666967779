@import "color.scss";
@import "common.scss";

.wrapper {
  ul {
    li {
      a,
      p {
        margin-left: -15px;
      }
    }
  }
}

.sidebar-brand {
  height: 40px !important;
}

#wrapper.toggled #page-content-wrapper {
  margin-right: -300px !important;
}

#wrapper.toggled #sidebar-wrapper {
  width: 300px !important;
}

#wrapper.toggled {
  padding-left: 300px !important;
}

.sidebar-nav {
  width: 330px !important;
}

.sidebar-fullscreen {
  // display: none;
  width: 100vw !important;
  height: 100vh !important;
  position: fixed !important;
  top: 0 !important;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1021 !important;

  &.sidebar-nav {
    display: none !important;

    &.show {
      display: block !important;
    }
  }
}

.accor-btn {
  &:not(.collapsed) {
    .accor-icon {
      transform: rotate(90deg);
      transition: transform 0.3s ease-in-out;
    }
  }

  &.collapsed {
    .accor-icon {
      transform: rotate(0deg);
      transition: transform 0.3s ease-in-out;
    }
  }
}

#sidebar-wrapper {
  left: 300px !important;
  margin-left: -300px !important;
  padding-bottom: 80px;
}

.side-content {
  background-color: $bg-color;
  //   background-color: yellow;
  border-radius: 5px;
  // min-height: $sidebarHeight;
  margin: 10px;
  margin-bottom: 10px;
  padding: 10px 0;
  overflow: hidden;

  &.bg2 {
    background-color: $bg-color-2;
  }

  &-body {
    min-height: $heightWithoutNav;
  }
}

.sider_bar_btn {
  width: 330px;
  height: 70px;
  position: fixed;
  bottom: 0;
  left: 0px;
  z-index: 777;

  .filter-btn {
    width: 150px;
    height: 35px;
  }
}

@media (min-width: 768px) {
  #wrapper {
    padding-left: 0px !important;
  }

  #sidebar-wrapper {
    width: 300px !important;
  }
}

.item-detail {
  width: 100%;
}
