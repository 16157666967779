$color: #495376;
$bg-color: #ffffff;
$bg-color-2: #edf1fb;
$bg-li-color: #6f7793;
$bg-card-color: #ecf3ff;
$disable-color: #999999;
$btn-color: #96e5ff;
$btn-color-2: #61ad89;
$btn-color-3: #db5e5f;
$btn-color-4: #6eb790;
$btn-color-5: #ff5b13;
$btn-color-7: #e98d3d;
$btn-disable: #d9d9d9;
$btn-edit: #ed7004;
$btn-active-color: #fffd00;
$border-color: #ced4da;
$default-color: #d9d9d9;
$icon-color: #00ff3b;
$bg-popover-color: #3a3b41;
$tab-color: #96e5ff;
$tag-color: #db5e5f;
$bookmark-color: #ffe600;
$placeholder-color: #262e4380;
$text-danger-color: #db5e5f;
$badge-color: #ff0000;
$modal-bg-color: linear-gradient(180deg, #4b597f 0%, #1f2639 100%),
  linear-gradient(0deg, #495376, #495376);
